@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

/*Web Wide Styles*/
*{
    margin: 0;
    padding: 0;
}
body, html
{
    background-color: #E5F2FF;
    font-family: "Inter", "Sans-Serif", "Times New Roman";
    font-style: normal;
    font-weight: 500;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
}
.homeLinkBtn {
    margin-bottom: 0.7rem; 
    padding: 0.8rem 0; 
    border-radius: 5px;
    font-size: 1.2rem; 
    text-decoration: none; 
    display: block;
}
.homeLinkBtnFastFind {
    background-color: #01244C;
    color: #DDBA2C;
}
.homeLinkBtnFastFind:hover {
    background-color: #DDBA2C;
    color: #01244C;
}
.homeLinkBtnDetailedFind {
    background-color: #DDBA2C;
    color: #01244C;
}
.homeLinkBtnDetailedFind:hover {
    background-color: #01244C;
    color: #DDBA2C;
}

.editBtn {
    margin-bottom: 0.7rem; 
    padding: 0.8rem 0; 
    border-radius: 5px;
    font-size: 1.2rem; 
    text-decoration: none; 
    display: block;
    background-color: #DDBA2C;
    color: #01244C;
}
.editBtn:hover {
    background-color: #01244C;
    color: #DDBA2C;
    border: 2px solid #DDBA2C !important;
}
.deleteBtn {
    margin-bottom: 0.7rem; 
    padding: 0.8rem 0; 
    border-radius: 5px;
    font-size: 1.2rem; 
    text-decoration: none; 
    display: block;
    background-color: #dd2c2c;
    color: #e9edf2;
}
.deleteBtn:hover {
    background-color: #01244C;
    color: #dd2c2c;
    border: 2px solid #dd2c2c !important;
}

.UniversalFieldsTB
{
    border-radius: 5px;
    background-color: lightgray;
    border: none;
    box-shadow: 1px 1px 1px 1px grey;
    height: 12vh;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    resize: vertical;
    padding-left: 1vw;
}
.UniversalTFFormat
{
    font-size: 1vw;
    font-family: "Inter", "Sans-Serif", "Times New Roman";
    font-style: normal;
    font-weight: 300;
}
.inlineFlex
{
    width: 100%;
    display: inline-flex;
}
.container
{
    max-width: 100%;
    height: 100%;
}
.UniversalRadioContainer
{
    display: inline-flex;
    height: 6vh;
    max-height: 6vh;
    width: 100%;
    max-width: 100%;
    justify-content: center;
    flex-grow: 1;
}
.UniversalRadioContainer input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}
.UniversalRadioContainer label {
    display: inline-flex;
    margin-right: 8px;
    padding: 1vw 2vw;
    border-radius: 5px;
    background-color: lightgray;
    border: none;
    box-shadow: 1px 1px 1px 1px grey;
    font-size: 1vw;
    font-family: "Inter", "Sans-Serif", "Times New Roman";
    font-style: normal;
    font-weight: 300;
    text-align: center;
    justify-content: center;
}
.UniversalRadioContainer input[type="radio"]:checked + label {
    background-color: #848482;
}
.UniversalRadioContainer label:hover {
    background-color: #E5E4E2;
}
.RadioChoiceContainer
{
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    height: 100%;
    justify-content: center;
}
.RadioChoiceContainer label
{
    align-items: center;
}
.UniversalRadioFix
{
    display: block;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6vh;
}
.UniversalCheckboxFix
{
    display: flex;
    width: 100%;
    height: 6vh;
    accent-color: #01244C;
}
.CheckboxInnerTitle
{
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 1vw;
    text-align: center;
    justify-content: center;
    align-items: center;
}



/*Navigation Bar*/
.navMainContainer
{
    background-color: #01244C;
    color: white;
    padding: 0 10px 0 10px;
    margin-bottom: 12px;
}
.navElementContainer
{
    display: flex;
    margin: auto;
    width: 100%;
    align-items: center;
}
.navElement1
{
    color: white;
    padding: 1vw;
    margin-right: auto;
    text-decoration: none;
    font-size: 1vw;
}
.navElement2
{
    color: white;
    padding: 1vw;
    margin: 1vw;
    text-decoration: none;
    font-size: 1vw;
}
.navElement2:focus
{
    color: #DDBA2C;
    font-weight: 800;
}
.navElement3
{
    color: white;
    padding: 1vw;
    margin-left: auto;
    text-decoration: none;
    font-size: 1vw;
}
.navElement3:focus
{
    color: #DDBA2C;
    font-weight: 800;
}
.navElement4
{
    font-weight: 700;
    padding: 3px;
    border: 2px solid black;
    font-size: 1vw;
}
.navElement4:hover
{
    background-color: #DDBA2C;
}
.navElement5
{
    color: white;
    padding: 1vw;
    margin: 1vw;
    text-decoration: none;
}
.navElement5:focus
{
    color: #DDBA2C;
    font-weight: 800;
}
.navElement6
{
    color: white;
    padding: 1vw;
    margin: 1vw;
    text-decoration: none;
    border: none;
}
.navElement6:focus
{
    color: #DDBA2C;
    font-weight: 800;
}



/* Home Page */
.homeContentContainer
{
    display: inline-flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;

}
.homeLeftContainer
{
    display: flex;
    float: left;
    width: 20%;
}
.homeFFColumn
{
    width: 20%;
    text-align: center;
}
.homeFFButton
{
    background-color: #DDBA2C;
    color: #01244C;
    border: 2px solid #01244C;
    border-radius: 5px;
    padding: 1vw 1vw;
    text-decoration: none;
    display: block;
    width: 100%;
}
.homeFFButton:hover
{
    background-color: #01244C;
    color: #DDBA2C;
    border: 2px solid #01244C;
}
.homeDFColumn
{
    width: 20%;
    text-align: center;
}
.homeDFButton
{
    background-color: #01244C;
    color: #DDBA2C;
    border: 2px solid #01244C;
    border-radius: 5px;
    width: 100%;
    padding: 1vw 1vw;
    display: block;
    text-decoration: none;
}
.homeDFButton:hover
{
    background-color: #DDBA2C;
    color: #01244C;
    border: 2px solid #01244C;
}
.homeButtonMargin
{
    margin: 15px 0px;
    list-style-type: none;
}
.homeList
{
    margin: 15px 0px;
}
.homeListLeft
{
    text-decoration: none;
    background-color: #01244C;
    color: #DDBA2C;
    border: 2px solid #01244C;
    border-radius: 5px;
    padding: 1vw 1vw;
    margin-top: 15px;
    display: block;
    width: 100%;
}
.homeListLeft:hover
{
    background-color: #DDBA2C;
    color: #01244C;
    border: 2px solid #01244C;
}
.homeListRight
{
    text-decoration: none;
    background-color: #DDBA2C;
    color: #01244C;
    border: 2px solid #01244C;
    border-radius: 5px;
    padding: 1vw 1vw;
    margin-top: 15px;
    display: block;
    width: 100%;
}
.homeListRight:hover
{
    background-color: #01244C;
    color: #DDBA2C;
    border: 2px solid #01244C;
}
.homeRightContainer
{
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
}
.homeImage{
    display: flex;
    width: 75%;
    height: 75%;
}
.homeMainText
{
    text-align: center;
    font-size: 3vw;
    font-weight: 800;
    color: #01244C;
}



/* Login Page */
.loginContainer
{
    display: inline-block;
    position: absolute;
    background-color: #01244C;
    color: white;
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 0
}
.loginTextContainer
{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 2vw 0;
}
.loginMainText
{
    font-size: 3.5vw;
    text-align: center;
    color: #01244C;
    font-weight: 800;
}
.loginFormContainer
{
    display: inline;
    width: 100%;
    text-align: left;
    color: #DDBA2C;
    font-size: 2vw;
    font-weight: 400;
}
.loginLabel
{
    text-align: center;
    display: block;
}
.loginTextFields
{
    display: inline-block;
    width: 35%;
    height: 4vw;
    background-color: #FFF;
    border: 4px solid #000;
    border-radius: 25px;
    font-size: 2vw;
    text-align: center;
}
.loginTextFields[type=text]
{
    background-color: #000;
    color: #fff;
}
.loginButton
{
    display: block;
    position: relative;
    width: 20%;
    height: 4vw;
    background-color: #FFF;
    color: #000;
    border: 4px solid #000;
    border-radius: 25px;
    font-weight: 600;
    font-size: 1.5vw;
    text-align: center;
    margin: auto;
}
.loginButton:hover
{
    background-color: #DDBA2C;
    color: #000;
    border: 2px solid #01244C;
}



/* Profile Page */
.profileContainer
{
    display: inline-flex;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 30%;
    padding-top: 50px;
    justify-content: center;
}
.profileLeftColumn
{
    position: relative;
    display: flex;
    height: 100%;
    width: 25%;
    padding: 10vw;
    float: left;
    margin-right: 20px;
    border: 2px solid #01244C;
    border-radius: 40px;
    background-color: #FFFFFF;
}
.profileLE1
{
    position: absolute;
    display: block;
    top: 0;
    left: 59%;
    transform: translate(-59%, 0);
}
.profileLE1 img
{
    max-width: 75%;
    height: auto;
    align-items: center;
}
.profileLE2
{
    position: absolute;
    display: block;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -60%);
    font-size: 2vw;
    text-align: center;
}
.profileLE3
{
    position: absolute;
    display: block;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -80%);
    font-size: 2vw;
    text-align: center;
}
.profileRightColumn
{
    position: relative;
    display: flex;
    height: 100%;
    width: 25%;
    padding: 10vw;
    float: left;
    margin-left: 20px;
    border-radius: 40px;
    background-color: #01244C;
}
.profileRightColumn h1
{
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
    color: #DDBA2C;
    font-size: 3vw;
}
.profileRightColumn h3
{
    position: relative;
    display: flex;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -25%);
    color: #FFFFFF;
    font-size: 1.3vw;
}



/* Detailed Find Page */
.DFOuterContainer
{
    position: relative;
    display: flex;
    max-width: 100%;
    height: auto;
    text-decoration: none;
}
.DFInnerContainer
{
    position: relative;
    display: block;
    margin: auto;
    margin-top: 0.5vw;
    text-decoration: none;
    height: auto;
}
.DFLeftColumn
{
    position: relative;
    display: block;
    float: left;
    margin-right: 2vw;
    margin-bottom: 1vw;
    padding: 3vw 0;
    width: 25vw;
    height: auto;
    border: 2px solid #01244C;
    border-radius: 15px;
    background-color: #FFFFFF;
    text-align: center;
    text-decoration: none;
}
.DFLeftColumn p
{
    position: relative;
    display: block;
    text-align: center;
    font-size: 1.5vw;
    font-weight: 700;
    color: #01244C;
    margin-bottom: 2vw;
}
.DFLeftColumn span
{
    margin: auto;
    position: relative;
}
.DFLeftButtons
{
    position: relative;
    display: flex;
    font-weight: 700;
    color: #DDBA2C;
    background-color: #01244C;
    border: 2px solid #01244C;
    height: 3rem;
    margin: 0 1rem;
    border-radius: 8px;
    text-decoration: none;
    align-items: center;
    justify-content: center;
}
.DFLeftButtons:focus
{
    color: #01244C;
    background-color: #DDBA2C;
    border: 2px solid #01244C;
}
.DFLeftButtons:hover
{
    color: #01244C;
    background-color: #DDBA2C;
}
.DFRightColumn
{
    position: relative;
    display: block;
    float: right;
    width: 60vw;
    height: auto;
    border: 2px solid #01244C;
    border-radius: 15px;
    background-color: #FFFFFF;
    margin-bottom: 1vw;
}
.DFBottomBar
{
    display: inline-flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 100%;
    justify-content: right;
    float: right;
    padding-right: 1rem;
}
.DFBottomBarInnerContainer
{
    margin: 0 0.5rem;
}
.DFBottomBarButton1
{
    font-size: 1rem;
    color: #01244C;
    background-color: #DDBA2C;
    width: 13rem;
    height: 3rem;
    border-radius: 5px;
    border: none;
    font-weight: 600;
}
.DFBottomBarButton1:hover
{
    color: #DDBA2C;
    background-color: #01244C;
}
.DFBottomBarButton2
{
    font-size: 1rem;
    color: #DDBA2C;
    background-color: #01244C;
    border-radius: 5px;
    border: none;
    font-weight: 600;
    text-decoration: none;
    padding: 1rem 3rem;
}
.DFBottomBarButton2:hover
{
    color: #01244C;
    background-color: #DDBA2C;
}

/* Detailed Find Components */
.DFUniversalContainer
{
    position: relative;
    padding: 2vw;
}
.DFUniversalTitle
{
    color: #01244C;
    font-size: 1.5vw;
    margin-bottom: 1.5vw;
}
.DFUniversalForm
{
    position: relative;
    width: 100%;
}
.DFUniversalRow
{
    display: inline-flex;
    margin-bottom: 1rem;
    width: 100%;
}
.DFUniversalData
{
    display: flex;
    margin: 0 1rem;
    flex-grow: 1;
    width: 100%;
    align-items: center;
}
.DFUniversalData label
{
    width: 100%;
}
.DFUniversalInnerTitle
{
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 1vw;
}
.DFUniversalFields
{
    border-radius: 5px;
    background-color: lightgray;
    border: none;
    box-shadow: 1px 1px 1px 1px grey;
    height: 3rem;
    width: 98%;
    padding-left: 1rem;
    font-size: 1rem;
    font-family: "Inter", "Sans-Serif", "Times New Roman";
    font-style: normal;
    font-weight: 300;
    align-items: center;
}



/* FF */

.FFRightColumn
{
    position: relative;
    display: block;
    float: right;
    width: 70vw;
    height: auto;
    border-radius: 15px;
    margin-bottom: 1vw;
}
.FFUniversalContainer
{
    position: relative;
    padding: 4vw;
}
.FFUniversalTitle
{
    color: #01244C;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
}
.FFResultsContainer
{
    margin-top: 2rem;
    background-color: #FFFFFF;
    border: 2px solid #01244C;
    border-radius: 15px;
    height: auto;
    min-height: 35rem;
}
.FFUniversalInnerTitle
{
    color: #01244C;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 1rem;
}

/* Report Page */

.InlineForm
{
    display: flex;
    align-items: center;
}
.RPUniversalInnerTitle
{
    font-weight: 700;
    font-size: 1vw;
    white-space: nowrap;
}
.RPCTSTitle
{
    color: #01244C;
    font-weight: 800;
    font-size: 1.25vw;
}
.RPCTSText
{
    color: #000;
    font-size: 1vw;
}
.RPCTSRow
{
    display: inline-block;
    margin-top: 3vw;
    margin-bottom: 2vw;
    width: 100%;
}
.RPCTSData
{
    display: block;
    margin: 0 1vw;
    flex-grow: 1;
    width: 100%;
}
.RPCTSTitleAlt
{
    color: #01244C;
    font-weight: 800;
    font-size: 1.25vw;
    margin: auto;
}



/* Query Result */

.QRContainer
{
    display: flex;
    margin: 2.5% 2.5%;
    min-height: 500px;
    background-color: #01244C;
    border-radius: 20px;
    height: auto;
}
.QRInnerContainer
{
    min-width: 100%;
    display: flex;
    color: #FFF;
}
.QRTitle
{
    color: #DDBA2C;
    font-size: 2vw;
}
.QRResultText
{
    color: #FFFFFF;
    font-size: 1vw;
    margin-top: 5px

}
.QRCContainer
{
    display: block;
    width: 100%;
    margin: 2vw;
    align-items: center;
}
.QRRow
{
    display: flex;
    margin-bottom: 1vw;
    width: 100%;
    border-bottom: 1px solid #FFF;
}
.QRData
{
    display: block;
    justify-content: start;
    flex-grow: 1;
    width: 100%;
    margin: 0.5% 0;
}



/* Consent for Release of Medical Information */

.CRMICheckboxFix
{
    display: flex;
    width: 25%;
    height: 6vh;
    accent-color: #01244C;
}



/* About Page */

.AboutContainer
{
    margin: 2.5% 2.5%;
    min-height: 500px;
    background-color: #01244C;
    border-radius: 20px;
    height: auto;
    display: block;
    text-align: center;
    border: 5px solid #DDBA2C;
}
.AboutRow
{
    display: inline-flex;
    width: 100%;
}
.AboutData
{
    width: 50%;
    align-items: center;
}
.AboutTitle1
{
    display: flex;
    color: #DDBA2C;
    padding-top: 4vw;
    font-size: 3vw;
    justify-content: center;
    align-items: center;
}
.AboutTitle2
{
    display: flex;
    color: #DDBA2C;
    padding-bottom: 4vw;
    font-size: 2.5vw;
    justify-content: center;
    align-items: center;
}
.AboutName
{
    display: flex;
    color: #FFFFFF;
    font-size: 2vw;
    padding-bottom: 2vw;
    text-decoration: underline;
    justify-content: center;
    align-items: center;
}
.AboutDescription
{
    display: flex;
    color: #FFFFFF;
    font-size: 1vw;
    padding-bottom: 7vw;
    justify-content: center;
    align-items: center;
}
.AboutIcon
{
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1vw;
}